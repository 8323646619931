import React from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import {useHistory} from 'react-router';

function OrganizationSelector () {
  const { currentKratosSession, organizations, currentOrganization, setCurrentOrganization, switchOrganization } = useAuth();
  const history = useHistory();

  if (!currentKratosSession?.active || !organizations?.length) {
    return null;
  }

  const handleOrganizationChange = async (event) => {
    const selectedOrg = organizations.find(org => org.id === event.target.value);
    if (selectedOrg) {
      await switchOrganization(selectedOrg);
      // Now that everything is properly updated, force a re-render
      const currentPath = history.location.pathname + history.location.search;
      history.push('/');
      setTimeout(() => {
        history.push(currentPath);
      }, 0);
    }
  };

  return (
    <FormControl size="small" sx={{ minWidth: 200, margin: '1rem' }}>
      <Select
        value={currentOrganization?.id || ''}
        onChange={handleOrganizationChange}
        displayEmpty
        sx={{ 
          backgroundColor: 'white',
          '& .MuiSelect-select': { 
            py: 1 
          }
        }}
      >
        {organizations.map((org) => (
          <MenuItem key={org.id} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default OrganizationSelector; 