import React, { useState, useEffect } from 'react';
import { useParams, withRouter, useLocation } from 'react-router';
import {Box, Typography, CircularProgress, Container} from '@mui/material';
import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import KratosFlowUIForm from './components/KratosFlowUIForm';
import { kratosPublicUrl } from 'constants/apiRoutes';
import {useHistory} from 'react-router';
import {useAuth} from '../../contexts/AuthContext';
import el_background from '../../assets/icons/main/elemental_background.svg';
import {submitKratosFlowUI} from './lib/submitKratosFlowUI.js';

// TODO:
//  handle if already verified and coming to this from clicking a link
//  handle the different errors ( flow expired / page refresh )
//  prefill the code so it can work coming in from the email

// function getCsrfTokenValue (nodes) {
//     const csrfNode = nodes.find(node =>
//         node.attributes?.name === 'csrf_token' && node.attributes?.type === 'hidden'
//     );
//     return csrfNode ? csrfNode.attributes.value : null;
// }

const VerificationPage = () => {
    const { currentKratosSession } = useAuth();
    const [kratosFlow, setKratosFlow] = useState(null);
    const history = useHistory();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const flowId = searchParams.get('flow');

    const fetchVerificationFlow = useQuery({
        queryKey: ['verification-flow', flowId],
        queryFn: async () => {
            // If we have a flow ID, use it
            if (flowId) {
                const response = await axios.get(
                    `${kratosPublicUrl}/self-service/verification/flows?id=${flowId}`,
                    {
                        headers: {
                            Accept: 'application/json'
                        },
                        withCredentials: true
                    }
                );
                return response.data;
            }
            
            // If no flow ID, create a new flow
            const response = await axios.get(
                `${kratosPublicUrl}/self-service/verification/browser`,
                {
                    headers: {
                        Accept: 'application/json'
                    },
                    withCredentials: true
                }
            );
            
            // Update URL with new flow ID without triggering a reload
            const newUrl = `${location.pathname}?flow=${response.data.id}`;
            history.replace(newUrl);
            
            return response.data;
        },
        onError: (error) => {
            if (error.response?.status === 410) {
                // TODO: we need to show this better to the user to let them know the flow expired which 
                //  means a new email will be sent and they need to re-verify using the new email
                // Flow expired - remove flow ID and reload
                const newParams = new URLSearchParams(location.search);
                newParams.delete('flow');
                history.replace({
                    pathname: location.pathname,
                    search: newParams.toString()
                });
            }
        },
        retry: false
    });

    useEffect( () => {
        console.log('fetchVerificationFlow.data', fetchVerificationFlow.data);
        setKratosFlow(fetchVerificationFlow.data);
    }, [fetchVerificationFlow.data] );

    useEffect( () => {
        // if the state is 'choose_method' .. create the payload manually for our known email
        // https://www.ory.sh/docs/kratos/reference/api#tag/frontend/operation/updateVerificationFlow
        // and mutate it so we can get the next form iteration to actually show the user
        if( kratosFlow ) {
            if( kratosFlow?.state == 'choose_method' ) {
                // TODO: can actually swap this with our submitKratosFormUI helper
                // let csrfValue = getCsrfTokenValue(fetchVerificationFlow.data.ui.nodes);
            // let payload = {
            //     'email': currentKratosSession?.identity.verifiable_addresses[0].value,
            //     'method': 'code',
            //     'csrf_token': csrfValue
            // };
            // //console.log('payload', payload, 'action', formData.ui.action );
            // mutationSubmitChooseMethod.mutate(payload, { onSuccess: (data) => setKratosFlow(data) } );
            submitKratosFlowUI(kratosFlow.ui, {
                'email': currentKratosSession.identity.traits.email,
                'method': 'code'
            }).then(response => {
                // Set the UI from the response
                setKratosFlow(response);
                // setIsAutoSubmitting(false);
            }).catch(error => {
                // Check for UI in the error response
                if (error.response?.data) {
                    setKratosFlow(error.response.data);
                } 
                // else {
                //     console.error('Error auto-submitting for code:', error);
                //     // resets it back?
                //     setKratosFlow(kratosFlow);
                // }
                // setIsAutoSubmitting(false);
                });
            }
        }
    }, [kratosFlow, currentKratosSession] );

    // useEffect(() => {
    //     console.log('kratosFlow.state', kratosFlow?.state);
    //     console.log('kratosFlow.type', kratosFlow?.type);

    //     if (kratosFlow?.state === 'passed_challenge' && 
    //         kratosFlow?.type === 'browser' &&
    //         kratosFlow?.ui?.messages?.some(msg => msg.type === 'success')) {
            
    //         const pendingInviteCode = localStorage.getItem('pendingInviteCode');
    //         if (pendingInviteCode) {
    //             localStorage.removeItem('pendingInviteCode');
    //             history.push(`/auth/invite?code=${pendingInviteCode}`);
    //         } else {
    //             history.push('/dashboard');
    //         }
    //     }
    // }, [kratosFlow, history]);

    const handleFlowUpdate = (newFlow) => {
        // Only track the state we care about
        setKratosFlow(newFlow);
        console.log('newFlow', newFlow);
        
        // Check for verification success
        if (newFlow.state === 'passed_challenge' && 
            newFlow.type === 'browser') {
                
            console.log('passed challenge');

            const pendingInviteCode = localStorage.getItem('pendingInviteCode');
            if (pendingInviteCode) {
                localStorage.removeItem('pendingInviteCode');
                window.location.href = `/invite?code=${pendingInviteCode}`;
            } else {
                window.location.href = '/';
            }
        }
    };

    if (fetchVerificationFlow.isLoading) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
                <CircularProgress />
            </Box>
        );
    }

    if (fetchVerificationFlow.isError) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
                <Typography variant='h6' color='error'>
                    An error occurred while fetching verification flow.
                </Typography>
            </Box>
        );
    }

    return (
        <div className='el_background'>
            <img src={el_background} alt='el_background' />
                <Container component='main' maxWidth='xs' style={{margin: '120px 100px'}}>
                <Box p={3}>
                    <Typography variant='h4' gutterBottom>
                        Verification
                    </Typography>
                    {kratosFlow &&
                        <KratosFlowUIForm
                            flow={kratosFlow}
                            onFlowUpdate={handleFlowUpdate}
                            hideAllGroupTitles={true} 
                            messages={{
                                1080003: `Check your email ${currentKratosSession?.identity?.traits?.email} for a verification code`
                            }}
                        />
                    }
                </Box>
                </Container>
        </div>
    );
};

export default withRouter(VerificationPage);