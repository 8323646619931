import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Typography, Box, FormHelperText } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';

const KratosFlowUIForm = ({ 
    flow,           
    onFlowUpdate,   
    onSuccess,
    onError,
    ignoreUIGroups = [],
    hideGroupTitles = [],
    hideAllGroupTitles = false,
    messages = {},
    ...props 
}) => {
    const { register, handleSubmit, control, setValue, getValues, unregister } = useForm();
    const [currentFlow, setCurrentFlow] = useState(flow);

    // Use the UI portion of the flow, preferring currentFlow over prop
    const formUI = currentFlow?.ui || flow?.ui;

    // Simplified mutation without side effects
    const mutation = useMutation(
        (data) => axios.post(formUI.action, data)
    );

    // Handle mutation state changes
    useEffect(() => {
        if (mutation.isSuccess) {
            const response = mutation.data;
            console.log('Form submitted successfully:', response);

            if (response.data.continue_with?.length > 0) {
                const continueWith = response.data.continue_with[0];
                if (continueWith.action === 'show_verification_ui' && continueWith.flow?.url) {
                    window.location.href = continueWith.flow.url;
                    return;
                }
            }
            
            if (response.data) {
                setCurrentFlow(response.data);
                // Only notify parent of significant state changes
                if (response.data.state !== currentFlow?.state) {
                    onFlowUpdate?.(response.data);
                }
            }

            if (onSuccess) {
                onSuccess(response);
            }
        }

        if (mutation.isError) {
            const error = mutation.error;
            console.error('Form submission failed:', error);

            if (error.response?.data?.error?.id) {
                switch (error.response.data.error.id) {
                case 'browser_location_change_required':
                    window.location.href = error.response.data.redirect_browser_to;
                    break;
                case 'session_already_available':
                    window.location.href = '/';
                    break;
                }
            } else if (error.response?.data) {
                setCurrentFlow(error.response.data);
                // Only notify parent of significant state changes
                if (error.response.data.state !== currentFlow?.state) {
                    onFlowUpdate?.(error.response.data);
                }
            }
            if (onError) onError(error);
        }
    }, [mutation.status, mutation.data, mutation.error]);

    // Handle form submission and include only the clicked submit button
    const onSubmit = (data, event) => {
        const submitter = event.nativeEvent.submitter; // Get the submit button that was clicked

        // Check if the submitter exists (i.e., a submit button was clicked)
        if (submitter) {
            console.log('submitter', submitter);
            const submitterName = submitter.name;
            const submitterValue = submitter.value;

            // Remove other submit button values from the payload
            formUI.nodes
                .filter((node) => node.attributes.type === 'submit' && node.attributes.name !== submitterName)
                .forEach((node) => unregister(node.attributes.name));

            console.log('getValues', getValues());
            console.log('data', data );
            // Ensure the clicked submit button's value is included
            setValue(submitterName, submitterValue);
        }

        // Trigger mutation on form submit
        mutation.mutate({ ...getValues() });
    };

    // Update groupNodes to filter out ignored groups
    const groupNodes = () => {
        return formUI.nodes.reduce((groups, node) => {
            const group = node.group || 'default';
            // Skip nodes in ignored groups
            if (ignoreUIGroups.includes(group)) {
                return groups;
            }
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(node);
            return groups;
        }, {});
    };

    const groupedNodes = groupNodes();

    // Render the shared inputs (from the 'default' group)
    const renderDefaultInputs = () => {
        const defaultNodes = groupedNodes['default'] || [];
        return defaultNodes.map((node, index) => renderNode(node, `default_${index}`));
    };

    // Render the messages for an input
    const renderMessages = (messages) => {
        return messages.map((message) => {
            // Check for message override - can be string or JSX
            const messageContent = messages[message.id] || message.text;
            
            switch (message.type) {
            case 'error':
                return (
                    <FormHelperText key={message.id} error>
                        {messageContent}
                    </FormHelperText>
                );
            case 'info':
                return (
                    <Typography key={message.id} variant='body2' color='textSecondary'>
                        {messageContent}
                    </Typography>
                );
            default:
                return null;
            }
        });
    };

    const renderNode = (node, index) => {
        const { attributes, meta } = node;
        // Only get label for visible input fields
        const shouldShowLabel = node.type === 'input' && !['hidden', 'submit'].includes(attributes.type);
        const label = shouldShowLabel ? (meta?.label?.text || attributes?.name) : null;
        const uniqueKey = `${attributes?.name || attributes?.id}_${index}`;

        // Determine the type of element to render based on node.type
        switch (node.type) {
            case 'input':
                // For input elements, use attributes.type to determine specific input type
                switch (attributes.type) {
                    case 'hidden':
                        return (
                            <input 
                                key={uniqueKey} 
                                type="hidden" 
                                {...register(attributes.name)} 
                                defaultValue={attributes.value} 
                            />
                        );
                    case 'submit':
                        return (
                            <Button
                                key={uniqueKey}
                                variant="contained"
                                type="submit"
                                disabled={attributes.disabled || mutation.isLoading}
                                value={attributes.value}
                                name={attributes.name}
                            >
                                {meta?.label?.text || attributes.value}
                            </Button>
                        );
                    case 'text':
                    case 'email':
                    case 'password':
                        return (
                            <Box key={uniqueKey} mb={2}>
                                <Controller
                                    name={attributes.name}
                                    control={control}
                                    defaultValue={attributes.value || ''}
                                    rules={{ 
                                        required: attributes.required, 
                                        pattern: attributes.pattern ? new RegExp(attributes.pattern) : undefined 
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={label}
                                            type={attributes.type}
                                            required={attributes.required}
                                            disabled={attributes.disabled}
                                            autoComplete={attributes.autocomplete}
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            error={!!(node.messages && node.messages.some((msg) => msg.type === 'error'))}
                                        />
                                    )}
                                />
                                {node.messages && renderMessages(node.messages)}
                            </Box>
                        );
                    default:
                        console.log('Unhandled input type:', attributes.type, node);
                        return null;
                }

            case 'a':
                return (
                    <a
                        key={uniqueKey}
                        href={attributes.href}
                        id={attributes.id}
                        className="inline-block px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800 hover:underline"
                    >
                        {attributes.title?.text || meta?.label?.text}
                    </a>
                );

            default:
                console.log('Unhandled node type:', node.type, node);
                return null;
        }
    };

    const renderGroupForm = (group, nodes) => {
        return (
            <form
                key={group}
                onSubmit={handleSubmit((data, event) => onSubmit(data, event))}
            >
                {/* Render shared default inputs */}
                {renderDefaultInputs()}

                {/* Render inputs specific to this group */}
                {nodes.map((node, index) => renderNode(node, `${group}_${index}`))}

            </form>
        );
    };

    return (
        <>
            {formUI.messages && formUI.messages.map((message) => (
                <Typography key={message.id} variant='body2' color='textSecondary'>
                    {messages[message.id] || message.text}
                </Typography>
            ))}

            {Object.entries(groupedNodes).map(([group, nodes]) => (
                group !== 'default' && (
                    <Box key={group} mb={3}>
                        {!hideAllGroupTitles && !hideGroupTitles.includes(group) && (
                            <Typography variant='h6' gutterBottom>
                                {group.charAt(0).toUpperCase() + group.slice(1)}
                            </Typography>
                        )}
                        {renderGroupForm(group, nodes)}
                    </Box>
                )
            ))}
        </>
    );
};

KratosFlowUIForm.propTypes = {
    flow: PropTypes.oneOfType([
        PropTypes.object,  // full flow object
        PropTypes.shape({  // just UI
            action: PropTypes.string,
            method: PropTypes.string,
            nodes: PropTypes.array
        })
    ]),
    onFlowStateChange: PropTypes.func,  // existing callback
    onFlowUpdate: PropTypes.func,       // new callback
    ignoreUIGroups: PropTypes.arrayOf(PropTypes.string), // Add prop type for ignoreUIGroups
    hideGroupTitles: PropTypes.arrayOf(PropTypes.string), // Add prop type
    hideAllGroupTitles: PropTypes.bool, // Add prop type
    messages: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node
    ])),
};

export default KratosFlowUIForm;
