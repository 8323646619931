import axios from 'axios';

export const submitKratosFlowUI = async (flowUI, customValues) => {
    const actionUrl = flowUI.action;
    const method = flowUI.method;
    
    const payload = {};

    console.log('submitKratosFlowUI', flowUI);

    // Helper function to set nested object value using dot notation
    const setNestedValue = (obj, path, value) => {
        const keys = path.split('.');
        let current = obj;
        
        for (let i = 0; i < keys.length - 1; i++) {
            if (!(keys[i] in current)) {
                current[keys[i]] = {};
            }
            current = current[keys[i]];
        }
        current[keys[keys.length - 1]] = value;
    };

    // Add all inputs from nodes first
    flowUI.nodes.forEach(node => {
        const attrs = node.attributes;
        if (attrs.node_type === 'input') {
            setNestedValue(payload, attrs.name, attrs.value || '');
        }
    });

    // Override with custom values
    Object.entries(customValues).forEach(([key, value]) => {
        setNestedValue(payload, key, value);
    });

    const response = await axios({
        url: actionUrl,
        method: method,
        data: payload,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        withCredentials: true
    });

    return response.data;
}; 