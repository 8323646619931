import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Layout from 'components/Templates/Layout';
import VerificationPage from './pages/auth/VerificationPage';
import { CircularProgress, Box } from '@mui/material';

const LoadingScreen = () => (
    <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh"
    >
        <CircularProgress />
    </Box>
);

const ProtectedRoute = ({ component: Component, componentType: ComponentType, pageName: name, edit, name: subName, ...rest }) => {
    const { 
        currentKratosSession,
        currentKratosError,
        organizations, 
        invites, 
        authIsReady 
    } = useAuth();

    // Show loading screen while auth is loading
    if (!authIsReady && currentKratosSession) {
        console.log('Protected Route - auth not ready as entities are loading');
        return <LoadingScreen />;
    }

    console.log('authIsReady, orgs, invite', authIsReady, organizations, invites);

    return (
        <Route
            {...rest}
            render={(props) => {

                // Check for authentication
                if (currentKratosError || !currentKratosSession?.active) {
                    return <Redirect to="/login" />;
                }

                // Check for email verification
                if (!currentKratosSession.identity.verifiable_addresses[0].verified) {
                    if (props.location.pathname !== '/verification') {
                        console.log('redirect to verification');
                        return <Redirect to="/verification" />;
                    }
                    return <VerificationPage />;
                }

                // Check for pending invites
                const storedCode = localStorage.getItem('pendingInviteCode');
                if (storedCode) {
                    return <Redirect to={`/invite?code=${encodeURIComponent(storedCode)}`} />;
                }

                if (invites?.length > 0) {
                    return <Redirect to={`/invite?code=${encodeURIComponent(invites[0].verification_code)}`} />;
                }

                // Check for organization membership
                const noOrgPaths = ['/invite'];
                if (organizations?.length < 1 && !noOrgPaths.includes(props.location.pathname)) {
                    return <Redirect to="/create-organization" />;
                }

                // If all checks pass, render the protected component
                // Only want to do this if we have orgs list, current org, invites, etc .. rolled up in authIsReady
                return (
                    <Layout 
                        main={<Component edit={edit} subName={subName} />} 
                        pageName={name} 
                        {...props} 
                    />
                );
            }}
        />
    );
};

export default ProtectedRoute;
 